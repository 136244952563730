<template>
  <v-container>
    <v-row justify="space-around">
      <v-card width="400">
        <v-img
          height="200px"
          src="https://i.postimg.cc/x1F90NxJ/logo.jpg"
        >
          <v-app-bar
            flat
            color="rgba(0, 0, 0, 0)"
          >

            <v-spacer></v-spacer>

            <v-btn
              color="white"
              icon
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </v-app-bar>

        </v-img>

        <v-card-text>
          <div class="font-weight-bold ml-8 mb-2">
            Detalle de la transaccion: <strong>{{reserva.get_estadoreserva.estado}}</strong>
           
          </div>

          <v-timeline
            align-top
            dense
          >
            <v-timeline-item
              v-for="message in messages"
              :key="message.key"
              :color="message.color"
              small
            >
              <div>
                <div class="font-weight-normal">
                  <strong>{{ message.from }}</strong> {{ message.time }}
                </div>
                <div>{{ message.message }}</div>
              </div>
            </v-timeline-item>
          </v-timeline>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    data: () => ({
      reserva: "",
      messages: [

      ],
    }),
    created () {
      let arr = window.location.href.split('data=');
      this.getReserva(arr[1]);
    },
    methods: {
        async getReserva(id) {
          try {
            const response = await this.axios.get("/reserva/buyOrder/"+id);
            this.reserva = response.data
            console.log(this.reserva)
            this.messages= [
              {
                from: 'Orden de compra',
                message: this.reserva.buy_order,
                time: ':',
                key: Math.random(),
                color: 'green',
              },
              {
                from: 'Servicio',
                message: this.reserva.servicio,
                time: ':',
                key: Math.random(),
                color: 'green',
              },
              {
                from: 'Total',
                message: this.reserva.precio,
                time: ':',
                key: Math.random(),
                color: 'green',
              },
              {
                from: 'Fecha',
                message: this.reserva.fecha,
                time: ':',
                key: Math.random(),
                color: 'green',
              },
              {
                from: 'Hora inicio',
                message: this.reserva.inicio,
                time: ':',
                key: Math.random(),
                color: 'green',
              },
              /** 
              {
                from: 'Hora termino',
                message: this.reserva.termino,
                time: ':',
                key: Math.random(),
                color: 'green',
              },
              */
              {
                from: 'Nombre',
                message: this.reserva.nombre,
                time: ':',
                key: Math.random(),
                color: 'green',
              },
              {
                from: 'Correo',
                message: this.reserva.email,
                time: ':',
                key: Math.random(),
                color: 'green',
              },
              {
                from: 'Telefono',
                message: this.reserva.telefono,
                time: ':',
                key: Math.random(),
                color: 'green',
              },
              {
                from: 'Nota',
                message: this.reserva.nota,
                time: ':',
                key: Math.random(),
                color: 'green',
              }
            ];
          } catch (error) {
        }
      }
    }
  }
</script>